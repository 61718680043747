<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <div slot="ctrl-button" style="margin-bottom: 12px">
            <el-button type="primary" plain @click="dialogExport=true">导出</el-button>
            <el-button type="primary" plain @click="routerGo" style="float:right">返回</el-button>
        </div>
        <ayl-table :table="table"></ayl-table>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        // nav: [{name: "出纳结算", path: ""}, {name: "出纳收付报表", path: "/cashier-settlement/cashier-payment/cashier-payment",query: {
        //     startTime: this.$route.query.startTime,
        //     endTime: this.$route.query.endTime,
        //     accId: this.$route.query.accId,
        // }}, {name: "明细", path: ""}],
        nav: [{name: "出纳结算", path: ""}, {name: "出纳收付报表", path: "/cashier-settlement/cashier-payment/cashier-payment"}, {name: "明细", path: ""}],
        table: {
          api: vm.$api.listSteelSettlementDetailReport,
          query: {
            startTime: this.$route.query.startTime,
            endTime: this.$route.query.endTime,
            tradeType: this.$route.query.tradeType,
            accId: this.$route.query.accId,
          },
          columns: [
            {
              title: '账户账号',
              width: '170px',
              key: 'companyAcc',
            }, {
              title: '对方账户',
              key: 'partnerAcc',
            }, {
              title: '对方户名',
              key: 'partnerAccName',
            }, {
              title: '对方开户银行',
              key: 'partnerAccBank',
            }, {
              title: '交易日期',
              filter: 'str2ymd',
              key: 'settlementDate',
            }, {
              title: '交易金额',
              key: 'tradeAmount',
            }, {
              title: '对账单编号',
              width: '170px',
              key: 'accountCheckingId',
            }, {
              title: '交易类型',
              key: 'tradeType',
            }, {
              title: '结算方式',
              key: 'settlementForm',
            }, {
              title: '出纳员',
              key: 'nickName',
            }, {
              title: '操作时间',
              width: '170px',
              render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.operationTime).format('YYYY-MM-DD HH:mm:ss')),
              ])}
            }, {
              title: '备注',
              key: 'settlementRemark',
            }]
        }
      }
    },
    methods: {
      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.listSteelExcel({
            params: {
                startTime: this.$route.query.startTime,
                endTime: this.$route.query.endTime,
                tradeType: this.$route.query.tradeType,
                accId: this.$route.query.accId,
            },
            excelKey: 'listSteelSettlementDetailReport',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
      routerGo(){
          this.$router.go(-1)
      }
    },

    beforeRouteLeave (to, from, next) {
      to.meta.keepAlive = true 
      next()
    },

    async mounted() {
      // await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listSteelSettlementDetailReport"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    },
    // async activated () {
    //   await this.$search(this.table)
    // },
  }
</script>

<style lang='sass' scoped>
.content-box /deep/ .el-form-item
    width: 41%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
</style>
